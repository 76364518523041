/*!
 * Copyright (C) 2019 PrinterCare - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 *
 * @copyright 2019 PrinterCare
 * @link       http://www.printer-care.de
 *
 */

/*------------------------------------------------------------------
[General elements style]
*/

html {
  height: 100%;
  overflow-x: hidden;
  font-size: $font-size-root;
}

body {
  padding: 0;
  background-color: $body-background;
  height: auto;
  min-height: 100%;
  line-height: 1.42857143;
  font-size: $font-size-base;

  &.be-alt-bg {
    background-color: darken($body-bg, 2%);
  }
}

a {

  &:hover, &:focus {
    text-decoration: none;
    outline: none;
  }
}

p {
  font-size: $paragraph-font-size;
  line-height: $paragraph-line-height;
}

//Fix for plugins like charts
canvas {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.custom-control {
  padding-left: 2.2rem;
}

.user-info-list table tr td {
  padding: 12px 10px;
}
